/* eslint-disable @typescript-eslint/member-ordering */
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';

import { AssignPopupComponent } from '@modules/observation/components/assign-popup/assign-popup.component';
import { WorkorderPopupComponent } from '@modules/observation/components/workorder-popup/workorder-popup.component';
import { ArchivePopupComponent } from '@modules/observation/components/archive-popup/archive-popup.component';
import { CancelworkorderPopupComponent } from '@modules/observation/components/cancelworkorder-popup/cancelworkorder-popup.component';
import { ObservationService } from '@services/observations/observation.service';
import { UtilsService } from '@services/utils/utils.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { TeamsService } from '@services/teams/teams.service';
import { SettingsService } from '@services/settings/settings.service';
import { UserService } from '@services/user/user.service';
import { ObjectsService } from '@services/objects/objects.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { Feature, Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { LoadingService } from '@services/loading/loading.service';
import { CollectionItemType, CollectionsService } from '@services/collections/collections.service';
import { ObservationGalleryImage } from '@modules/observation/components/image-gallery/image-gallery.component';
import { PopupObservationService } from '@services/observationDetail/popup-observation.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { IObjectStringKeyMap } from '@shared/models';
import { ISelectItem, ISelectList } from '@modules/observation/components';
import { AssetState, ModuleAccessService } from '@services';
import { CustomField, CustomFields, CustomFieldType } from '@modules/customFields/custom-fields.interfaces';
import {
  Observation,
  ObservationHistoryRelatedData,
  ObservationStatusCode
} from '@services/observations/observation.interfaces';
import { findLast } from 'lodash';
import { CustomFieldsService } from '@modules/customFields/services';

export enum HistoryItemRelatedType {
  pdca = 'pdca',
  event = 'event',
  valueChange = 'valueChange'
}

export interface IObservationHistoryItem {
  activity: string;
  fullName?: string;
  name: string;
  observer?: boolean;
  relatedItem?: string;
  relatedType?: HistoryItemRelatedType;
  relatedData?: any;
  time: string;
  unixTime?: number;
  userID: number;
  userImg: string;
  userActive: boolean;
  linkTitle?: string;
  linkRoute?: string;
}

interface ObservationImage {
  imgSrc: string;
  class: string;
  time: string;
  name: string;
  fullName?: string;
  mediaType: string;
  isPending?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ObservationDetailService {

  public static readonly dataNavMap = {
    condition: 'pages/dashboard/condition-detail/',
    behavior: 'pages/dashboard/coaching-detail/',
    compliment: 'pages/dashboard/compliment-detail/',
    pi: 'pages/dashboard/process-detail/',
    quality: 'pages/dashboard/quality-detail/',
    ca: 'pages/dashboard/ca-detail/',
    si: 'pages/dashboard/opportunities/detail/',
    ai: 'pages/dashboard/assets/detail/'
  };

  // this holds the data set to build the footer menu to navigate between
  // the observation lists

  public dataSet: any = [];
  public resolveFromSupervisor = false;

  // flags to resolve ca and go back to check result table
  public resolveToResultTable = false;
  public resolveFromCheckResultTable: any;


  // this is the reference of the table from which we came to detail, required for bottom and possibly back nav.
  public activeTable: string = null;

  public singleOption = {
    width: '100%',
  };

  private historyMap = {
    created: 'Logged',
    resolved: 'Closed',
    fixed: 'Fixed',
    assigned: 'Assigned',
    escalated: 'Escalated',
    claimed: 'Claimed',
    dropped: 'Scrapped',
    updated: 'Updated'
  };

  private processHistoryMap = {
    created: 'Submitted',
    resolved: 'Closed',
    fixed: 'Implemented',
    assigned: 'Assigned',
    escalated: 'Assigned',
    claimed: 'Claimed',
    updated: 'Updated'
  };

  private submissionHistoryMap = {
    created: 'Submitted',
    fixed: 'Addressed',
    updated: 'Updated'
  };

  private processCaMap = {
    created: 'Submitted',
    resolved: 'Resolved',
    fixed: 'fixed',
    assigned: 'Assigned',
    escalated: 'Assigned',
    claimed: 'Claimed',
    updated: 'Updated'
  };

  private selectMap: IObjectStringKeyMap<ISelectList> = {
    more: {
      values: [],
      // onSelect: (oid: string, item: ISelectItem) => console.log('onSelect', oid, item),
      name: 'SHARED.More_Actions',
      class: ''
    }
  };

  private buttonMap = {
    assign: {
      name: 'SHARED.Assign_Observation',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => {
        this.assignObservation(oid);
      }
    },
    assignUser: {
      name: 'SHARED.Assign_Observation',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => {
        this.assignObservationUser(oid);
      }
    },
    workorder: {
      name: 'ODetail.Work_Order_Required',
      color: 'secondary',
      class: 'page-button',
      feature: 'workorder',
      click: (oid) => {
        this.createWorkorder(oid);
      }
    }
    ,
    cancelWorkorder: {
      name: 'SHARED.CANCELWORKORDER_POPUP_title',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => {
        this.cancelWorkorder(oid);
      }
    }
    ,
    archive: {
      name: 'ODetail.Archive',
      color: 'light',
      class: 'page-button button-right',
      click: (oid) => {
        this.archiveObs(oid);
      }
    },
    close: {
      name: 'SHARED.CLOSE_POPUP_title',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => {
        this.closeObs(oid);
      }
    },
    changeType: {
      name: 'ODetail.Change_Type',
      color: 'secondary',
      class: 'page-button',
      click: (oid, headerIsHidden) => this.changeType(oid, headerIsHidden)
    },
    markAsFixed: {
      name: 'SHARED.Mark_As_Fixed',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => this.markAsFixed(oid)
    },
    markAsResolved: {
      name: 'SHARED.Mark_As_Resolved',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => this.markAsResolved(oid)
    },
    addTags: {
      name: 'TOPICS.Add_Tag',
      color: 'secondary',
      class: 'page-button',
      click: (oid) => this.router.navigate([`/pages/management/topics/add-tags/${oid}`], {queryParams: {type: CollectionItemType.Observation}})
    },
    getFeedback: {
      name: 'MGMT_DETAILS.Get_Feedback',
      color: 'secondary',
      class: 'page-button',
      module: 'inquiries',
      click: (id: number) => this.router.navigate(['/pages/dashboard/feedback/get-feedback'], {
        queryParams: {
          parentID: id,
          parentType: 'observation'
        }
      })
    },
    addTopic: {
      name: 'TOPICS.Create_Add_Topic',
      color: 'secondary',
      class: 'page-button',
      click: (id: number) => {
        this.collectionsService.itemCollection = [{
          itemID: id,
          type: CollectionItemType.Observation,
          item: id.toString()
        }];
        this.router.navigate(['pages/management/add-topic']);
      }
    },
    moveToAddressed: {
      name: 'MGMT_DETAILS.Move_To_Addressed',
      color: 'secondary',
      class: 'page-button',
      click: (id) => this.showAddressPopup(id)
    }
  };

  private oidArray: any = [];
  private headerMap = {
    condition: {
      name: 'ODetail.Condition_Detail',
      data: ['timeOpen', 'owner', 'location', 'zone', 'team', 'severity', 'likelihood'],
      buttonPanel: true,
    },
    behavior: {
      name: 'ODetail.Coaching_Detail',
      data: ['logged', 'loggedBy', 'location', 'zone'],
      buttonPanel: true,
    },
    compliment: {
      name: 'ODetail.Thumbs-Up_Detail',
      data: ['logged', 'loggedBy', 'recipients', 'location', 'zone'],
      buttonPanel: true,
    },
    quality: {
      name: 'ODetail.Quality_Detail',
      data: ['timeOpen', 'owner', 'location', 'zone', 'team'],
      buttonPanel: true,
    },
    pi: {
      name: 'ODetail.Process_Detail',
      data: ['submitted', 'submittedBy', 'location', 'zone', 'piType'],
      buttonPanel: true,
    },
    ca: {
      name: 'DASHPAGES.corrective-action-detail',
      data: ['caSubmitted', 'caSubmittedBy', 'owner', 'location', 'zone', 'team'],
      buttonPanel: true,
    },
    si: {
      name: 'DASHPAGES.Opportunity_Detail',
      data: ['submitted', 'submittedBy', 'location', 'zone', 'team'],
      buttonPanel: true
    },
    ai: {
      name: 'DASHPAGES.Asset_Detail',
      data: ['timeOpen', 'owner', 'location', 'zone', 'team'],
      buttonPanel: true
    }
  };

  public aiStatuses = {
    [AssetState.Down]: {
      type: 'red',
      title: 'PROPERTY.State_Down'
    },
    [AssetState.Strained]: {
      type: 'yellow',
      title: 'PROPERTY.State_Strained'
    },
    [AssetState.Up]: {
      type: 'green',
      title: 'PROPERTY.State_Up'
    }
  };

  // mapped list of available function for header elements
  // if you add any elements to header, add to this list to make it easy for reference.
  private functionMap = {
    timeOpen: (data) => this.timeOpen(data),
    owner: (data) => this.owner(data),
    location: (data) => this.location(data),
    zone: (data) => this.zone(data),
    team: (data) => this.team(data),
    severity: (data) => this.severity(data),
    likelihood: (data) => this.likelihood(data),
    logged: (data) => this.logged(data),
    loggedBy: (data) => this.loggedBy(data),
    submitted: (data) => this.submitted(data),
    submittedBy: (data) => this.submittedBy(data),
    piType: (data) => this.obsPiType(data),
    recipients: (data) => this.recipients(data),
    assetType: (data) => this.assetType(data),
    targetSig: (data) => this.targetSig(data),
    caSubmitted: (data) => this.caSubmitted(data),
    caSubmittedBy: (data) => this.caSubmittedBy(data),
  };

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private observations: ObservationService,
    private utils: UtilsService,
    private accountService: AccountsService,
    private teamService: TeamsService,
    private settingsService: SettingsService,
    private userService: UserService,
    private objectService: ObjectsService,
    private popover: PopoverController,
    private permissionsService: PermissionsService,
    private subscriber: SubscriberService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private alertController: AlertController,
    private popupObservationService: PopupObservationService,
    private collectionsService: CollectionsService,
    private moduleAccessService: ModuleAccessService,
    private customFieldsService: CustomFieldsService
  ) {
  }

  // NOTE: REMOVE WHEN COMPLETE
  // basic URL parsing should be done on individual page on ionViewWill/Did Enter.
  // brainstorm on types of pages required, seems like it would be beneficial to add multi pages for each
  // type of observation.

  public getLikelihood(oid) {
    const observation = this.observations.observations.data[oid];
    return this.observations.getProperty(observation, 'likelihood');
  }

  public getQualityDetailData(oid) {
    const retObj: any = {};
    const observation = this.observations.observations.data[oid];
    const subtype: string = this.observations.getProperty(observation, 'subtype');
    retObj.subtype = subtype;
    // following prop will come from backend eventually
    if (subtype === 'receiving') {
      retObj.qualType = this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_receiving');
      retObj.fields = this.getCustomFieldData('qualityReceivingFields', observation);
    } else if (subtype === 'rma') {
      retObj.qualType = this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_rma');
      retObj.fields = this.getCustomFieldData('qualityRMAFields', observation);
    } else if (subtype === 'production') {
      retObj.qualType = this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_production');
      retObj.fields = this.getCustomFieldData('qualityProductionFields', observation);
    }

    if (observation.state === 'fixed') {
      retObj.fixType = this.translate.instant('MGMT_DETAILS.Repaired');
    } else if (observation.state === 'dropped') {
      retObj.fixType = this.translate.instant('MGMT_DETAILS.Scrapped');
    }
    return retObj;
  }

  public getCaDetailData(oid) {
    // this would be fetched using responseId from observation object
    return {
      name: 'Forklift Pre-check',
      question: 'It be working?',
      answer: true,
      target: 'Forklift 1',
      type: 'Asset'
    };
  }

  public getClosingNoteData(oid) {
    const retObj: any = {};
    const observation: any = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');
    const subtype: string = this.observations.getProperty(observation, 'subtype');
    if ((_.includes(['condition', 'ca', 'quality', 'ai'], type) || (type === 'pi' && subtype === 'general')) && observation.state === 'resolved') {
      retObj.closeNotes = true;
      retObj.notes = [];
      retObj.type = type;
      retObj.categories = [];
      retObj.tags = [];
      retObj.observationID = oid;

      if (_.includes(['condition', 'quality', 'ai'], type)) {
        retObj.impact = observation.impact;

        if (type === 'condition') {
          _.assign(retObj, {
            risk: _.pick(observation, [
              'likelihood',
              'severity',
              'likelihoodOverride',
              'severityOverride',
              'severityClosed',
              'likelihoodClosed'
            ])
          });
        }
      }

      if (type === 'pi' && subtype === 'general') {
        retObj.currency = observation.value;
      }

      _.each(observation.notes, note => {
        if (note.subtype === 'closingNotes' && note.type === 'text') {
          const tempNote = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          retObj.notes.push(tempNote);
        }
      });

      _.each(observation.categories, (categoryId: any) => {
        // we have to switch between cats and qual cats depending on the type of observation!!!!
        let catNote: any = {};
        if (type === 'condition') {
          catNote = _.find(this.settingsService.categories.data, <any>{messageID: +categoryId});
        } else if (type === 'quality') {
          catNote = _.find(this.settingsService.qualityCats.data, <any>{messageID: +categoryId});
        } else if (type === 'pi') {
          catNote = _.find(this.settingsService.piCats.data, <any>{messageID: +categoryId});
        } else if (type === 'ai') {
          catNote = _.find(this.settingsService.aiCats.data, <any>{messageID: +categoryId});
        }

        if (catNote) {
          retObj.categories.push(catNote.messageTitle);
        }
      });

      if (observation.tagIDs) {
        observation.tags = observation.tagIDs;
      }

      _.each(observation.tags, (tagID: number) => {
        const tagObject: any = _.find(this.settingsService.customTags.data, <any>{tagID});

        if (tagObject) {
          retObj.tags.push(tagObject.tag);
        }
      });

      let resolvedUser = _.find(observation.history, ['activity', 'resolved']);

      if (!resolvedUser) {
        // that means we don't have proper info on data, let's pick one user
        resolvedUser = observation.history[0];
      }

      retObj.user = {
        name: this.accountService.getCompactName(resolvedUser.userID),
        userImg: this.accountService.avatar(resolvedUser.userID, 64, false, true)
      };

      retObj.notes = _.sortBy(retObj.notes, 'unixTime').reverse();

      // if closing note for process, we want saving to go as well
      if (type === 'pi' && subtype === 'general') {
        retObj.savBool = true;
        if (_.isNumber(+observation.value)) {
          retObj.saving = observation.value;
        } else {
          retObj.saving = null;
        }

      }
    } else {
      retObj.closeNotes = false;

    }
    return retObj;
  }

  public getSeverity(oid) {
    const observation = this.observations.observations.data[oid];
    return this.observations.getProperty(observation, 'severity');
  }

  public getHistory(oid): IObservationHistoryItem[] {
    const observation = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');
    const historyArray: any = [];
    let mapArray: any = this.historyMap;
    // We have different activity mapping for process improvement, So
    if (type === 'pi') {
      mapArray = this.processHistoryMap;
    }

    if (type === 'si') {
      mapArray = this.submissionHistoryMap;
    }
    if (type === 'ca') {
      mapArray = this.processCaMap;
    }
    _.each(observation.history, hist => {
      if (hist.activity !== 'updated') {
        let activity = mapArray[hist.activity];
        if (!activity) {
          activity = _.upperFirst(hist.activity);
        }
        const histObj: IObservationHistoryItem = {
          activity,
          name: this.accountService.getCompactName(hist.userID),
          fullName: this.accountService.fullUserName(hist.userID),
          unixTime: hist.time,
          userID: hist.userID,
          relatedItem: hist.relatedItem,
          relatedType: hist.relatedType,
          relatedData: hist.relatedData,
          time: this.utils.dateTimeFormat(hist.time, '', true),
          userImg: this.accountService.avatar(hist.userID, 64, false, true),
          userActive: this.accountService.isActive(hist.userID),
          observer: this.accountService.getAccount(hist.userID)?.type === 'observer'
        };
        historyArray.push(histObj);
      } else {
        // we are looking at updated - are there details
        if (hist?.relatedData && typeof hist.relatedData === 'object' && hist.relatedData?.field) {
          const histObj: IObservationHistoryItem = {
            activity: this.historyMap.updated,
            name: this.accountService.getCompactName(hist.userID),
            fullName: this.accountService.fullUserName(hist.userID),
            unixTime: hist.time,
            userID: hist.userID,
            relatedData: hist.relatedData,
            time: this.utils.dateTimeFormat(hist.time, '', true),
            userImg: this.accountService.avatar(hist.userID, 64, false, true),
            userActive: this.accountService.isActive(hist.userID),
            observer: this.accountService.getAccount(hist.userID)?.type === 'observer'
          };
          historyArray.push(histObj);
        }
      }
    });
    return historyArray;
  }

  public getCaNotes(oid) {
    const observation = this.observations.observations.data[oid];
    let createdNotes: any = [];
    let closingNotes: any = [];
    // we don't need note types for Ca
    _.each(observation.notes, note => {
      if (note.type === 'text') {
        const noteObj = {
          data: note.value,
          type: 'text',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        if (note.subtype === 'closingNotes' || note.subtype === 'resolved') {
          closingNotes.push(noteObj);
        } else {
          createdNotes.push(noteObj);
        }

      }
    });

    _.each(observation.attachments, note => {
      if (note.mediaType === 'audio/mp4') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        if (note.subtype === 'closingNotes' || note.subtype === 'fixed') {
          closingNotes.push(noteObj);
        } else {
          createdNotes.push(noteObj);
        }
      }
    });

    createdNotes = _.sortBy(createdNotes, 'unixTime').reverse();
    closingNotes = _.sortBy(closingNotes, 'unixTime').reverse();

    return {
      createdNotes,
      closingNotes
    };
  }

  public getPiNotes(oid) {

    const observation = this.observations.observations.data[oid];
    const createdNotes: any = [];
    const fixedNotes: any = [];
    const reasonNotes: any = [];
    const returnObj: any = {};
    const subtype: string = this.observations.getProperty(observation, 'subtype');

    // check if waiting or general?
    returnObj.type = subtype;
    if (subtype === 'general') {
      if (observation.state === 'new' || observation.state === 'escalated') {
        returnObj.doubleNotes = false;
      } else {
        returnObj.doubleNotes = true;
      }
    }

    _.each(observation.notes, note => {
      if (subtype === 'waiting') {
        if (note.subtype === 'fixIdea' && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          createdNotes.push(noteObj);
        }

        if ((note.subtype === 'created' || note.subtype === '') && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          createdNotes.push(noteObj);
        }

        if (note.subtype === 'reason' && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          reasonNotes.push(noteObj);
        }
      }

      if (subtype === 'general') {
        if (note.subtype === 'fixed' && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          fixedNotes.push(noteObj);
        } else if ((note.subtype === 'created' || note.subtype === '') && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          createdNotes.push(noteObj);
        } else if ((note.subtype === 'generalIdea') && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          returnObj.generalIdea = noteObj;
        } else if ((note.subtype === 'fixIdea') && note.type === 'text') {
          const noteObj = {
            data: note.value,
            type: 'text',
            time: this.utils.dateTimeFormat(note.time),
            name: this.accountService.getCompactName(note.userID),
            unixTime: note.time,
            userImg: this.accountService.avatar(note.userID, 64, false, true)
          };
          returnObj.fixIdea = noteObj;
        }
      }
    });

    // GET AUDIO NOTES
    _.each(observation.attachments, note => {
      if (note.subtype === 'fixIdea' && note.mediaType === 'audio/mp4' && subtype === 'waiting') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        createdNotes.push(noteObj);
      } else if (note.subtype === 'fixed' && note.mediaType === 'audio/mp4' && subtype === 'general') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        fixedNotes.push(noteObj);
      } else if (note.mediaType === 'audio/mp4' && note.subtype === 'generalIdea') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        returnObj.audioIdea = noteObj;
      } else if (note.mediaType === 'audio/mp4' && note.subtype === 'fixIdea') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        returnObj.audioFix = noteObj;
      } else if (note.mediaType === 'audio/mp4') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        createdNotes.push(noteObj);
      }
    });


    returnObj.reasonNotes = _.sortBy(reasonNotes, 'unixTime').reverse();
    returnObj.createdNotes = _.sortBy(createdNotes, 'unixTime').reverse();
    returnObj.fixedNotes = _.sortBy(fixedNotes, 'unixTime').reverse();
    returnObj.value = observation.value;
    return returnObj;
  }

  public getBbsNotes(oid) {
    const observation = this.observations.observations.data[oid];
    let commentNotes: any = [];
    const mitigationNotes: any = [];
    const behaviorNotes: any = [];
    const complimentNotes: any = [];

    // get categories message.
    const catBucket = observation.categories;
    _.each(catBucket, (item) => {
      let msgObj = this.settingsService.getItem('mitigations', item, true);

      if (!msgObj) {
        msgObj = this.settingsService.getItem('behaviors', item, true);
      }

      if (!msgObj) {
        msgObj = this.settingsService.getItem('compliments', item, true);
      }

      if (msgObj && msgObj.type === 'behavior') {
        behaviorNotes.push(this.craftNoteObj(msgObj));
      }

      if (msgObj && msgObj.type === 'compliment') {
        complimentNotes.push(this.craftNoteObj(msgObj));
      }

      if (msgObj && msgObj.type === 'mitigation') {
        mitigationNotes.push(this.craftNoteObj(msgObj));
      }

    });


    _.each(observation.notes, note => {
      if (note.subtype === 'comment' && note.type === 'text') {
        const noteObj = {
          data: note.value,
          type: 'text',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        commentNotes.push(noteObj);
      } else if (note.subtype === 'comment' && note.type === 'object') {
        const noteObj = {
          data: this.objectService.URI(note.value, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        commentNotes.push(noteObj);
      }
    });
    commentNotes = _.sortBy(commentNotes, 'unixTime').reverse();
    return {
      openNotes: commentNotes,
      behaviorNotes,
      mitigationNotes,
      complimentNotes
    };
  }

  // check on observation methods, it basically take categories and generates mesage list.
  public getNotes(oid) {
    const observation = this.observations.observations.data[oid];
    let openNotes: any = [];
    let closedNotes: any = [];
    let displayClosedNotes = false;
    let noteStatus: any = null;
    if (observation.state === 'dropped') {
      noteStatus = 'Scrapped';
    } else if (observation.state === 'fixed' || observation.state === 'resolved') {
      noteStatus = 'Fixed';
    }

    // two types of notes, audio and text.

    // GET TEXT NOTES
    _.each(observation.notes, note => {
      if (note.subtype === 'fixed') {
        const noteObj = {
          data: note.value,
          type: 'text',
          time: this.utils.dateTimeFormat(note.time, '', true),
          name: this.accountService.getCompactName(note.userID),
          fullName: this.accountService.fullUserName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        closedNotes.push(noteObj);
      } else if (note.subtype !== 'closingNotes') {
        const noteObj = {
          data: note.value,
          type: 'text',
          time: this.utils.dateTimeFormat(note.time, '', true),
          name: this.accountService.getCompactName(note.userID),
          fullName: this.accountService.fullUserName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        openNotes.push(noteObj);
      }
    });

    // GET AUDIO NOTES
    _.each(observation.attachments, note => {
      if (note.subtype === 'fixed' && note.mediaType === 'audio/mp4') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time, '', true),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        closedNotes.push(noteObj);
      } else if (note.mediaType === 'audio/mp4' && note.subtype !== 'fixed') {
        const noteObj = {
          data: this.objectService.URI(note.objectID, false),
          type: 'audio',
          time: this.utils.dateTimeFormat(note.time, '', true),
          name: this.accountService.getCompactName(note.userID),
          unixTime: note.time,
          userImg: this.accountService.avatar(note.userID, 64, false, true)
        };
        openNotes.push(noteObj);
      }
    });
    openNotes = _.sortBy(openNotes, 'unixTime').reverse();
    closedNotes = _.sortBy(closedNotes, 'unixTime').reverse();

    if ((observation.state === 'resolved' || observation.state === 'fixed' || observation.state === 'dropped') && observation.subtype !== 'receiving') {
      displayClosedNotes = true;
    }

    return {
      openNotes,
      closedNotes,
      displayClosedNotes,
      noteStatus
    };

  }

  public setNearestObservationIdsIfEmpty(ids: number[]): any {
    this.oidArray = ids;
  }

  // this navigates to speicific page depending on the type of data.
  public async navigateToDetail(data: any, dataSet: any, replaceUrl: boolean = false, tableNav: boolean = true, params: any = {}) {
    // find what type and nav.
    const queryParams: any = params;
    if (tableNav) {
      queryParams.tableNav = true;
    }
    this.oidArray = dataSet;
    if (data.uuid) {
      const obs = await this.observations.getObservationByUUID(data.uuid);
      this.router.navigate([ObservationDetailService.dataNavMap[this.observations.getProperty(obs, 'type')] + data.uuid], {
        replaceUrl,
        queryParams
      });
    } else {
      this.logger.error(`observation with ${data.observationID} ID does not have UUID`);
      setTimeout(() => {
        this.loadingService.disable();
      });
    }
  }

  public getTopComponentData(oid) {
    // need to get reference of data
    // make observation request promise based
    const returnData: any = {};
    returnData.oid = oid;
    const observation = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');
    const state: string = this.observations.getProperty(observation, 'state');
    returnData.title = this.translate.instant(this.headerMap[type].name);
    returnData.uuid = observation.uuid;
    const headerArray = this.headerMap[type].data;
    // now let's collect data.
    returnData.headerElements = [];
    _.each(headerArray, elem => {
      if (type == 'ca' && state == 'resolved' && elem === 'owner') {
        return;
      }
      // check if that function is available in function map at top
      if (this.functionMap[elem]) {
        returnData.headerElements.push(this.functionMap[elem](observation));
      }
    });
    // button panel elements

    // show hide pane
    returnData.buttonPanel = this.headerMap[type].buttonPanel;

    // what buttons to include.
    // if new or escalated, workorder..
    returnData.buttons = this.figureOutButtons(observation);
    returnData.selects = this.figureOutSelects();

    // check if in workorder state?
    if (observation.state === 'workorder') {
      returnData.workOrder = observation.workorder;
    }

    return returnData;
  }

  public getFooterData(oid) {
    const returnData: any = {};
    const observation = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');

    if (this.oidArray.length === 0) { // no observation to go back and forth?
      return returnData; // bail out
    } else {
      const currentPosition: number = this.oidArray.indexOf(oid);
      // if first of the table element.
      if (currentPosition > 0) {
        const previousObservationUUID: string = _.get(this.observations.observations.data[this.oidArray[currentPosition - 1]], 'uuid');

        returnData.prev = {
          disabled: false,
          data: ObservationDetailService.dataNavMap[type] + previousObservationUUID
        };
      } else {
        returnData.prev = {
          disabled: true
        };
      }

      // last element of the table
      if (currentPosition + 1 === this.oidArray.length) {
        returnData.next = {
          disabled: true
        };
      } else {
        const nextObservationUUID: string = _.get(this.observations.observations.data[this.oidArray[currentPosition + 1]], 'uuid');

        returnData.next = {
          disabled: false,
          data: ObservationDetailService.dataNavMap[type] + nextObservationUUID
        };
      }

    }
    switch (type) {
      case 'condition': {
        returnData.nav = 'pages/dashboard/condition-table';
        if (observation.state === 'new' || observation.state === 'escalated') {
          returnData.name = 'ODetail.All_Open_Observations';
          returnData.activeTab = 'open';
        } else if (observation.state === 'workorder') {
          returnData.name = 'ODetail.All_WO_Observations';
          returnData.activeTab = 'workorder';
        } else if (observation.state === 'fixed') {
          returnData.name = 'ODetail.All_Fixed_Observations';
          returnData.activeTab = 'fixed';
        } else if (observation.state === 'resolved') {
          returnData.name = 'ODetail.All_Closed_Observations';
          returnData.activeTab = 'closed';
        }
        break;
      }
      case 'quality': {
        returnData.nav = 'pages/dashboard/quality-table';
        if (this.observations.getProperty(observation, 'subtype') === 'receiving') {
          if (observation.state === 'resolved') {
            returnData.name = 'ODetail.All_Closed_Quality_Issues';
            returnData.activeTab = 'qualityClosed';
          } else {
            returnData.name = 'ODetail.All_Receiving_Issues';
            returnData.activeTab = 'qualityReceiving';
          }
        } else {
          // these are production
          if (observation.state === 'new' || observation.state === 'escalated') {
            returnData.name = 'ODetail.All_Open_Quality_Issues';
            returnData.activeTab = 'qualityOpen';
          } else if (observation.state === 'fixed' || observation.state === 'dropped') {
            returnData.activeTab = 'qualityFixed';
            returnData.name = 'ODetail.All_Repaired/Scrapped_Issues';
          } else if (observation.state === 'resolved') {
            returnData.name = 'ODetail.All_Closed_Quality_Issues';
            returnData.activeTab = 'qualityClosed';
          }
        }
        break;
      }
      case 'behavior': {
        returnData.nav = 'pages/dashboard/coaching-table';
        returnData.name = 'ODetail.All_Coaching_Opportunities';
        break;
      }
      case 'compliment': {
        returnData.nav = 'pages/dashboard/compliment-table';
        returnData.name = 'ODetail.All_Thumbs-Up';
        break;
      }
      case 'pi': {
        returnData.nav = 'pages/dashboard/process-table';
        if (observation.state === 'escalated' || observation.state === 'new') {
          if (observation.ownerID) {
            returnData.name = 'ODetail.All_Process_Assigned';
            returnData.activeTab = 'processAssigned';
          } else {
            returnData.name = 'ODetail.All_Process_Submitted';
            returnData.activeTab = 'processSubmitted';
          }
        } else if (observation.state === 'fixed') {
          returnData.name = 'ODetail.All_Process_Implemented';
          returnData.activeTab = 'processImplemented';
        } else if (observation.state === 'resolved') {
          returnData.name = 'ODetail.All_Process_Closed';
          returnData.activeTab = 'processClosed';
        }
        break;
      }
      case 'ca': {
        returnData.nav = 'pages/dashboard/ca-table';
        if (observation.state === 'new' || observation.state === 'escalated') {
          returnData.name = 'ODetail.All_Open_CA_Observations';
          returnData.activeTab = 'caOpen';
        } else if (observation.state === 'workorder') {
          returnData.name = 'ODetail.All_WO_CA_Observations';
          returnData.activeTab = 'caWorkorder';
        } else if (observation.state === 'resolved') {
          returnData.name = 'ODetail.All_Resolved_CA_Observations';
          returnData.activeTab = 'caClosed';
        }
        break;
      }
      case 'si': {
        returnData.nav = 'pages/dashboard/opportunities';
        returnData.name = 'DASHPAGES.All_Opportunities';

        if (observation.state === 'new') {
          returnData.activeTab = 'submitted';
        } else if (observation.state === 'fixed') {
          returnData.activeTab = 'addressed';
        }
        break;
      }
      case 'ai': {
        returnData.nav = 'pages/dashboard/assets';
        if (observation.state === 'new' || observation.state === 'escalated') {
          returnData.name = 'ODetail.All_Open_Asset_Observations';
          returnData.activeTab = 'open';
        } else if (observation.state === 'workorder') {
          returnData.name = 'ODetail.All_WO_Asset_Observations';
          returnData.activeTab = 'workorder';
        } else if (observation.state === 'fixed') {
          returnData.name = 'ODetail.All_Fixed_Asset_Observations';
          returnData.activeTab = 'fixed';
        } else if (observation.state === 'resolved') {
          returnData.name = 'ODetail.All_Closed_Asset_Observations';
          returnData.activeTab = 'closed';
        }
        break;
      }
    }
    return returnData;
  }

  public figureOutButtons(observation: any) {
    const retArray: any = [];
    let buttsArray: any = [];
    const type: string = this.observations.getProperty(observation, 'type');
    const subtype: string = this.observations.getProperty(observation, 'subtype');

    // 1. Open condition Buttons
    if (type === 'condition' && (observation.state === 'new' || observation.state === 'escalated')) {
      // check supervisor permission, and workorder enabled or not, to include workorder and archive.
      buttsArray = ['assign', 'workorder'];
    }

    // 2. Workorder buttons.
    if (type === 'condition' && observation.state === 'workorder') {
      buttsArray = ['assign', 'cancelWorkorder'];
    }

    // 3. fixed observation, both qual and cond.
    if (_.includes(['fixed', 'dropped'], observation.state) && type !== 'si') {
      buttsArray = ['close'];
    }

    // 4. Resolved, for all


    // 5. Open quality buttons
    if (type === 'quality' && (observation.state === 'new' || observation.state === 'escalated')) {
      // check supervisor permission, and workorder enabled or not, to include workorder and archive.
      if (subtype === 'receiving') {
        buttsArray = ['close'];
      } else {
        buttsArray = ['assign', 'markAsFixed', 'close'];
      }
    }

    // 6. Open process buttons
    if (type === 'pi') {
      // check supervisor permission, and workorder enabled or not, to include workorder and archive.
      if (observation.state === 'new' || observation.state === 'escalated') {
        buttsArray = ['assign', 'close'];
      }
      if (observation.state === 'fixed') {
        buttsArray = ['close',];
      }
    }

    // 7. Open Ca buttons
    if (type === 'ca') {
      if (observation.state === 'new' || observation.state === 'escalated') {
        buttsArray = ['assign', 'workorder', 'markAsResolved'];
      }
      if (observation.state === 'workorder') {
        buttsArray = ['assign', 'cancelWorkorder', 'markAsResolved'];
      }
      if (observation.state === 'fixed') {
        buttsArray = ['close'];
      }
    }

    // 8. Open Si buttons
    if (type === 'si') {
      buttsArray = ['addTags', 'getFeedback', 'addTopic'];

      if (observation.state === 'new') {
        buttsArray.push('moveToAddressed');
      }
    }

    // 9. Open Ai Buttons
    if (type === 'ai') {
      if (_.includes(['new', 'escalated'], observation.state)) {
        buttsArray = ['assign', 'workorder'];
      } else if (_.includes(['workorder', 'escalated'], observation.state)) {
        buttsArray = ['assign', 'cancelWorkorder'];
      }
    }

    // check feature access
    if ((this.permissionsService.canView(Permission.SuperAdmin)
      || (this.permissionsService.canView(Permission.Admin)
        && _.includes(this.subscriber.getFeature('change_observation_type', []), Permission.Admin))
      || (this.permissionsService.canView(Permission.Supervisor)
        && _.includes(this.subscriber.getFeature('change_observation_type', []), Permission.Supervisor))
    ) && observation.state !== 'resolved' && _.includes(['condition', 'quality', 'pi'], type)) {
      buttsArray.push('changeType');
    }

    _.each(buttsArray, butts => {
      const r = this.buttonMap[butts];
      if (!_.has(r, 'feature') || this.subscriber.usesFeature(r.feature)) {
        retArray.push(this.buttonMap[butts]);
      }
    });

    return retArray;
  }

  // TODO: find a way to refactor this permissions check stuff
  public getMoreActions(): ISelectList {
    let moreActionsArray: ISelectItem[] = [];
    const moreActionsMap: IObjectStringKeyMap<ISelectItem> = {
      addToPdca: {
        key: 'addToPdca',
        text: `${this.translate.instant('PDCA.Module_Name')}: ${this.translate.instant('PDCA.Add_PDCA')}`,
        onSelect: (oid: string) => {
          this.router.navigate(['/pages/dashboard/pdca-list/pdca', {oid}]);
        }
      },
      archive: {
        key: 'archive',
        text: 'DASHBOARD.ARCHIVE_POPUP_title',
        onSelect: (oid: string) => this.archiveObs(oid)
      }
    };

    const canViewSuper = this.permissionsService.canView(Permission.SuperAdmin);
    const canViewAdmin = this.permissionsService.canView(Permission.Admin);
    const canViewVisor = this.permissionsService.canView(Permission.Supervisor);

    if (canViewSuper
      || (canViewAdmin && _.includes(this.subscriber.getFeature(Feature.ARCHIVE_OBSERVATIONS, []), Permission.Admin))
      || (canViewVisor && _.includes(this.subscriber.getFeature(Feature.ARCHIVE_OBSERVATIONS, []), Permission.Supervisor))
    ) {
      moreActionsArray.push(moreActionsMap.archive);
    }

    if (this.moduleAccessService.isModuleEnabled(Module.PDCA) && this.moduleAccessService.isFeatureEnabled(Feature.PDCA)) {
      moreActionsArray.push(moreActionsMap.addToPdca);
    }

    const moreList = _.cloneDeep(this.selectMap.more);

    if (moreActionsArray.length) {
      moreList.values = moreActionsArray;
    } else {
      return null;
    }

    return moreList;
  }

  public figureOutSelects(): ISelectList[] {
    const retArray: ISelectList[] = [];

    // use only More actions for now
    const moreList = this.getMoreActions();

    if (moreList) {
      retArray.push(moreList);
    }

    return retArray;
  }

  public getGalleryImagesById(id: number): ObservationGalleryImage[] {
    const observation = this.observations.observations.data[id];
    const attachments = _.filter([...observation?.attachments, ...observation?.images], (attachmentsItem) => {
      const type = _.split(attachmentsItem.mediaType, '/')[0];
      return _.includes(['video', 'image'], type) || attachmentsItem.objectID === 0;
    });

    return _.map(attachments, (item) => ({
      objectID: item.objectID,
      mediaType: item.mediaType,
      time: item.time,
      ownerID: item.userID,
      subtype: item.subtype
    }));
  }

  public getImageBorderColor(observationId: number, imageSubType: string): string {
    if (!observationId) {
      return '';
    }

    const observation = this.observations.observations.data[observationId];
    const observationType: string = this.observations.getProperty(observation, 'type');
    const colorMap = {
      new: {
        condition: 'yellow-border',
        ca: 'red-border',
        quality: 'purple-border',
        pi: 'purple-border',
        si: 'opportunity-border',
      },
      fixed: 'green-border',
      qualityClass: {
        ca: 'red-border',
        si: 'opportunity-border'
      }
    };

    if (!imageSubType && observationType === 'ca') {
      imageSubType = 'new';
    }

    let imageColor = colorMap[imageSubType];

    if (_.isObject(imageColor)) {
      imageColor = imageColor[observationType];
    }

    return imageColor;
  }

  public getImages(oid): ObservationImage[] {
    const observation = this.observations.observations.data[oid];
    const type: string = this.observations.getProperty(observation, 'type');

    const retArr = _.map(observation.images, img => {
      const imgObj: ObservationImage = {
        imgSrc: this.objectService.URI(img.objectID, true),
        class: 'obs-detail-image ',
        time: this.utils.dateTimeFormat(img.time, '', true),
        name: this.accountService.getCompactName(img.userID),
        fullName: this.accountService.fullUserName(img.userID),
        mediaType: img.mediaType
      };

      if (img.objectID === 0) {
        imgObj.class += 'yellow-border empty-image';
        imgObj.isPending = true;
      } else if (img.subtype === 'new' && type === 'condition') {
        imgObj.class += 'yellow-border';
      } else if (img.subtype === 'new' && type === 'ai') {
        imgObj.class += 'azure-border';
      } else if ((img.subtype === 'new' || img.subtype === '') && type === 'ca') {
        imgObj.class += 'red-border';
      } else if (img.subtype === 'new' && (type === 'quality' || type === 'pi')) {
        imgObj.class += 'purple-border';
      } else if (img.subtype === 'fixed' && img.mediaType === 'image/jpeg') {
        imgObj.class += 'red-border';
      }

      return imgObj;
    });

    observation.attachments = _.filter(observation.attachments, (attachment) => !_.find(observation.images, (image) => attachment.objectID && attachment.objectID === image.objectID));

    // GET IMAGES FROM ATTACHMENTS
    _.each(observation.attachments, img => {
      const isMediaFile: boolean = _.includes(['image/jpeg', 'image/png', 'image/gif', 'video/mp4'], img.mediaType);

      if (img.subtype !== 'fixed' && type === 'condition' && isMediaFile) {
        const imgObj = {
          imgSrc: this.getObjectURI(img.objectID),
          class: 'obs-detail-image yellow-border',
          time: this.utils.dateTimeFormat(img.time, '', true),
          name: this.accountService.getCompactName(img.userID),
          mediaType: img.mediaType
        };
        retArr.push(imgObj);
      } else if (img.subtype !== 'fixed' && isMediaFile && (type === 'quality' || type === 'pi')) {
        const imgObj = {
          imgSrc: this.getObjectURI(img.objectID),
          class: 'obs-detail-image purple-border',
          time: this.utils.dateTimeFormat(img.time, '', true),
          name: this.accountService.getCompactName(img.userID),
          mediaType: img.mediaType
        };
        retArr.push(imgObj);
      }

      if (img.subtype === 'fixed' && isMediaFile) {
        const imgObj = {
          imgSrc: this.getObjectURI(img.objectID),
          class: 'obs-detail-image green-border',
          time: this.utils.dateTimeFormat(img.time, '', true),
          name: this.accountService.getCompactName(img.userID),
          mediaType: img.mediaType
        };
        retArr.push(imgObj);
      }

      if (_.includes(['qualityClass', 'new'], img.subtype) && isMediaFile && type === 'ca') {
        const imgObj = {
          imgSrc: this.getObjectURI(img.objectID),
          class: 'obs-detail-image red-border',
          time: this.utils.dateTimeFormat(img.time, '', true),
          name: this.accountService.getCompactName(img.userID),
          mediaType: img.mediaType
        };
        retArr.push(imgObj);
      }

    });

    return retArr;

  }

  public getBottomComponentData(oid) {

  }

  public logged(data) {
    return {
      name: 'SHARED.LOGGED',
      data: this.utils.dateTimeFormat(data.created)
    };
  }

  public submitted(data) {
    return {
      name: 'SHARED.SUBMITTED',
      data: this.utils.dateTimeFormat(data.created, null, true)
    };
  }

  public owner(data) {
    return {
      name: 'SHARED.OWNER',
      data: this.accountService.fullname(data.ownerID),
      message: (data.ownerID && this.accountService.isActive(data.ownerID)) ? true : false,
      userID: data.ownerID,
      userImg: this.accountService.avatar(data.ownerID, 64, false, true)
    };
  }

  public loggedBy(data) {
    return {
      name: 'SHARED.LOGGED_BY',
      data: this.accountService.fullname(data.userID),
      message: (data.userID && this.accountService.isActive(data.userID)) ? true : false,
      userID: data.userID,
      userImg: this.accountService.avatar(data.userID, 64, false, true)
    };
  }

  public caSubmittedBy(data) {
    // check if this is new/escalated of fixed
    const fixedDate = _.find(data.history, ['activity', 'resolved']);
    if (fixedDate) {
      return {
        name: 'DASHPAGES.Resolved_By',
        data: this.accountService.fullname(fixedDate.userID),
        message: (fixedDate.userID && this.accountService.isActive(fixedDate.userID)) ? true : false,
        userID: fixedDate.userID,
        userImg: this.accountService.avatar(fixedDate.userID, 64, false, true)
      };
    } else {
      return {
        name: 'SHARED.SUBMITTED_BY',
        data: this.accountService.fullname(data.userID),
        message: (data.userID && this.accountService.isActive(data.userID)) ? true : false,
        userID: data.userID,
        userImg: this.accountService.avatar(data.userID, 64, false, true)
      };
    }
  }

  public submittedBy(data) {
    let title = 'SHARED.SUBMITTED_BY';
    let uId = data.userID;
    if (data.subtype === 'general' && data.ownerID) {
      uId = data.ownerID;
      if (data.state === 'new' || data.state === 'escalated') {
        title = 'SHARED.ASSIGNED_TO';
      } else {
        title = 'SHARED.IMPLEMENTED_BY';
      }
    }
    return {
      name: title,
      data: this.accountService.fullname(uId),
      message: uId && this.accountService.isActive(uId),
      userID: uId,
      userImg: this.accountService.avatar(uId, 64, false, true)
    };
  }

  public assetType(data) {

  }

  public targetSig(data) {

  }

  public recipients(data) {
    const uObj: any = [];
    if (!_.isEmpty(data.groups)) {
      _.each(data.groups, gid => {
        const retObj = {
          userID: gid,
          data: this.teamService.teamNameByID(gid),
          userImg: null,
          team: true
        };
        uObj.push(retObj);
      });
    } else {
      _.each(data.recipients, uid => {
        const retObj = {
          userID: uid,
          data: this.accountService.fullname(uid),
          userImg: this.accountService.avatar(uid, 64, false, true)
        };
        uObj.push(retObj);
      });
    }
    return {
      name: 'SHARED.GIVEN_TO',
      data: uObj,
      recipients: true
    };
  }

  public obsPiType(data) {
    return {
      name: 'SHARED.TYPE',
      data: this.translate.instant('SHARED.' + _.upperFirst(this.observations.getProperty(data, 'subtype')))
    };
  }

  public location(data) {
    const locObj = this.userService.findLocation(data.locationID);
    return {
      name: 'SHARED.LOCATION',
      data: locObj ? locObj.name : 'N/A'
    };

  }

  public zone(data) {
    const zoneLocation = this.userService.findLocation(data.locationID);
    const zoneDetails = this.userService.findAnyZone(zoneLocation, data.zoneID);
    const dropDownOptions = this.userService.buildZoneMenu(data.locationID, false, true);

    return {
      name: 'SHARED.HOME_TS_ZONE',
      data: zoneDetails ? zoneDetails.name : 'N/A',
      selectMenu: true,
      value: data.zoneID ? data.zoneID : data.locationID + ':0',
      dropDownOptions,
      options: this.singleOption,
      class: 'table-content'
    };
  }

  public team(data) {
    return {
      name: 'SHARED.TEAM',
      data: this.teamService.teamNameByID(data.groupID)
    };
  }

  public severity(data) {
    return {
      name: 'SHARED.S',
      data: this.colorDot(Number(this.getSeverity(data.observationID))),
      image: true
    };

  }

  public likelihood(data) {
    return {
      name: 'SHARED.L',
      data: this.colorDot(Number(this.getLikelihood(data.observationID))),
      image: true
    };
  }

  /**
   * colorDot - determine the right color dot based upon a value
   *
   * Returns the value to use in an href
   */
  colorDot(val: number) {
    if (val >= 80) {
      return 'assets/images/highestDot.svg';
    } else if (val >= 60) {
      return 'assets/images/highDot.svg';
    } else {
      return 'assets/images/mediumDot.svg';
    }
  }

  public checkObservationType(observationId: number, type: string): void {
    const observation: any = this.observations.observations.data[observationId];

    if (observation) {
      const currentType: string = this.observations.getProperty(observation, 'type');

      if (currentType !== type) {
        this.router.navigate([this.getUrlByType(currentType)], {replaceUrl: true});
      }
    } else {
      this.router.navigate([this.getUrlByType(type)], {replaceUrl: true});
    }
  }

  public markNotes(tags: string[], target): void {
    _.each(tags, (tag: string) => {
      (<any>$(target)).mark(tag, {
        exclude: ['.marked', '.body-copy-bold', '.detail', '.observation-u-name'],
        accuracy: {
          value: 'exactly',
          limiters: ['!', '@', '#', '&', '*', '(', ')', '-', '–', '—', '+', '=', '[', ']', '{', '}', '|', ':', ';', '\'', '\"', '‘', '’', '“', '”', ',', '.', '<', '>', '/', '?']
        },
        className: 'marked',
        separateWordSearch: false
      });
    });
  }

  public getObjectURI(objectID: number): string {
    return objectID ? this.objectService.URI(objectID, true) : 'assets/icons/noimage.svg';
  }

  public getAIStatus(observation: Observation, fixedState?: boolean): ObservationStatusCode {
    const relatedData: ObservationHistoryRelatedData = findLast(observation?.history, (historyItem) => {
      return historyItem?.relatedData?.state === (fixedState ? 'fix' : 'new');
    })?.relatedData;

    const statusCode = this.getAIStatusByValue(+relatedData?.value);
    if (statusCode) {
      return {
        title: statusCode.title,
        type: statusCode.type
      };
    }
  }

  public getAIStatusByValue(value: number) {
    return this.aiStatuses[value];
  }

  private closeObs(oid) {
    this.router.navigate(['pages/dashboard/close-observation', {id: oid}]);
  }

  private markAsFixed(oid) {
    this.router.navigate(['pages/dashboard/mark-fixed'], {queryParams: {id: oid}});
  }

  private markAsResolved(oid) {
    this.router.navigate(['pages/dashboard/mark-resolve'], {queryParams: {id: oid}});
  }

  private archiveObs(oid) {
    this.popover.create(<any>{
      component: ArchivePopupComponent,
      animated: false,
      componentProps: {
        observationID: oid
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private cancelWorkorder(oid) {
    this.popover.create(<any>{
      component: CancelworkorderPopupComponent,
      animated: false,
      componentProps: {
        observationID: oid
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private showAddressPopup(observationId: number) {
    const params: any = {
      header: this.translate.instant('SHARED.Are_You_Sure'),
      message: this.translate.instant('MGMT_DETAILS.Change_Submission_Status'),
      cssClass: 'custom-alert',
      buttons: [
        {text: this.translate.instant('SHARED.Cancel')},
        {
          text: this.translate.instant('SHARED.Yes_Move_It'),
          handler: () => this.popupObservationService.toggleState(observationId)
        }
      ]
    };

    this.alertController.create(params).then((alert) => {
      alert.present();
    });
  }

  private createWorkorder(oid) {
    this.popover.create(<any>{
      component: WorkorderPopupComponent,
      animated: false,
      componentProps: {
        observationID: oid
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private assignObservation(oid) {
    // check role
    this.popover.create(<any>{
      component: AssignPopupComponent,
      animated: false,
      componentProps: {
        participants: [],
        observationID: oid
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  private assignObservationUser(oid) {
    // check role
    this.popover.create(<any>{
      component: AssignPopupComponent,
      animated: false,
      componentProps: {
        participants: [],
        observationID: oid,
        onlyUser: true
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();
    });
  }

  public getCustomFieldData(fields: CustomFields | string, obs: any, doTransform: boolean = true): any {
    // pull in the custom field definitions and the associated data
    const f = typeof(fields) === 'string' ? this.subscriber.getPreference(fields) as CustomFields : fields;
    let v = _.get(obs, 'value', []);
    if (typeof (v) === 'string') {
      try {
        v = JSON.parse(v);
      } catch (e) {
        v = [];
      }
    }

    const ret = [];
    if (f) {
      // we have the fields
      _.each(f.data, (data, idx) => {
        const fDef = { type: data.type, name: data.uuid, title: data.name, required: data.required } as any;
        if (data.type === CustomFieldType.SingleSelect || data.type === CustomFieldType.Multiselect) {
          fDef.options = this.customFieldsService.parseOptions(data.menuItems);
        }
        const r = {type: data.type, name: data.name, uuid: data.uuid, multiple: data.canAdd, values: []};
        let vals = _.filter(v, ['uuid', data.uuid]);
        if (!vals.length) {
          vals = _.filter(v, ['name', data.name]);
        }
        vals = _.filter(vals, ref => {
          if (ref.hasOwnProperty('value') && ref.value !== '' && ref.value !== null) {
            return true;
          }
        });
        if (!vals.length) {
          if (doTransform) {
            r.values.push('--');
          }
        } else {
          _.each(vals, (ref) => {
            if (ref.value !== '') {
              // maybe interpret the value
              if (doTransform) {
                ref.value = this.customFieldsService.parseFieldValue(ref.value, fDef.type, '--', fDef);
              }
              if (_.isArray(ref.value)) {
                r.values = ref.value;
              } else {
                r.values.push(ref.value);
              }
            }
          });
        }
        ret.push(r);
      });
    }
    return ret;
  }

  private craftNoteObj(note) {
    const noteObj = {
      data: note.messageTitle,
      type: 'text',
    };
    return noteObj;
  }

  private timeOpen(data) {
    // check if this is new/escalated of fixed
    const fixedDate = _.find(data.history, ['activity', 'fixed']);
    if (fixedDate) {
      return {
        name: 'SHARED.FIXED',
        data: this.utils.dateTimeFormat(fixedDate.time, null, true)
      };
    } else {
      return {
        name: 'SHARED.TIME_OPEN_1',
        data: moment(data.created * 1000).fromNow(true)
      };
    }
  }

  private caSubmitted(data) {
    // check if this is new/escalated of fixed
    const fixedDate = _.find(data.history, ['activity', 'resolved']);
    if (fixedDate) {
      return {
        name: 'DASHPAGES.resolved',
        data: this.utils.dateTimeFormat(fixedDate.time, null, true)
      };
    } else {
      return {
        name: 'SHARED.SUBMITTED',
        data: this.utils.dateTimeFormat(data.created, null, true)
      };
    }
  }

  private changeType(id: number, headerIsHidden: boolean): void {
    const queryParams: any = {isBackNavigation: headerIsHidden};
    this.router.navigate([`pages/dashboard/changing-type/${id}`], {queryParams});
  }

  private getUrlByType(type: string): string {
    let url = 'pages/dashboard/condition-table';

    if (type === 'quality') {
      url = 'pages/dashboard/quality-table';
    } else if (type === 'pi') {
      url = 'pages/dashboard/process-table';
    } else if (type === 'ca') {
      url = 'pages/dashboard/ca-table';
    } else if (type === 'ai') {
      url = 'pages/dashboard/assets';
    }

    return url;
  }

}

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Events } from '@services/events/events.service';

import {
  SettingsService,
  ResizeHandlerService,
  ObservationService,
  TableService,
  LoadingService,
  CheckResponseService,
  UtilsService
} from '@services';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { ITab } from '@modules/shared/components';
import { Response } from '@modules/management/pages/details/check/models';

import * as _ from 'lodash';
import * as uuid from 'uuid';
import { values } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-result-table',
  templateUrl: './check-result-table.page.html',
  styleUrls: ['./check-result-table.page.scss'],
})
export class CheckResultTablePage implements OnInit {
  public checkName: string;
  public tabs: ITab[] = [
    {value: 'SHARED.Completed', key: 'complete', postfix: '(0)'},
    {value: 'SHARED.Comments_Small', key: 'logged', postfix: '(0)'},
    {value: 'SHARED.Corrective_Actions', key: 'corrective', postfix: '(0)'},
    {value: 'DASHPAGES.Unable_To_Perform', key: 'skipped', postfix: '(0)'},
    {value: 'SHARED.Missed', key: 'missed', postfix: '(0)'}
  ];

  public tableIds = {
    complete: `complete_${uuid.v4()}`,
    logged: `logged_${uuid.v4()}`,
    corrective: `corrective_${uuid.v4()}`,
    skipped: `skipped_${uuid.v4()}`,
    missed: `missed_${uuid.v4()}`
  };

  public backNavigation: boolean;
  public currentTab: any = this.tabs[0];
  public targetConfig: any = {
    data: [],
    value: null,
    options: {}
  };
  public filterConfig: any = {
    title: 'SHARED.Check_Results',
    selectConfig: {
      label: this.translate.instant('SHARED.Timespan'),
      config: {
        data: [
          {id: 'day', text: 'SHARED.Today'},
          {id: 'yesterday', text: 'SHARED.Yesterday'},
          {id: 'week', text: 'SHARED.This_Week'},
          {id: 'month', text: 'SHARED.This_Month'},
          {id: 'quarter', text: 'SHARED.This_Quarter'},
          {id: '30days', text: 'SHARED.30_days'},
          {id: '60days', text: 'SHARED.60_days'},
          {id: '90days', text: 'SHARED.90_days'}
        ],
        value: this.checkDetailService.statusPageTimeRange.id,
        options: {
          minimumResultsForSearch: Infinity,
          sorter: (data) => data
        }
      }
    }
  };
  private currentFilter: any;
  private currentTeam: any;
  private checkID: number;
  private targetSignature: string;
  private flagIssues: any;
  private tableOffset = 0;
  private isLoading: boolean;
  private responses: Response[];

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private resizeHandler: ResizeHandlerService,
    private checkDetailService: CheckDetailService,
    private router: Router,
    private observationService: ObservationService,
    private observationDetailService: ObservationDetailService,
    private loadingService: LoadingService,
    private tableService: TableService,
    private checkResponseService: CheckResponseService,
    private events: Events,
    private location: Location
  ) {}

  ngOnInit() {
    this.backNavigation = this.activatedRoute.snapshot.paramMap.get('backNavigation') === 'true';
  }

  ionViewWillLeave() {
    this.events.unsubscribe('ccs:checkResponseUpdate', this.checkObservationListener);
    this.resizeHandler.removeResizeHandler('conditionTable');
  }

  ionViewWillEnter() {
    this.currentFilter = this.checkDetailService.statusPageTimeRange.currentFilter;

    if (!this.currentTeam) {
      this.currentTeam = this.checkDetailService.statusPageTimeRange.currentTeam;
    }
    if (!this.filterConfig.selectConfig.config.value) {
      if (this.checkDetailService.statusPageTimeRange.id) {
        this.filterConfig.selectConfig.config.value = this.checkDetailService.statusPageTimeRange.id;
      } else {
        this.applyTimeFilterPeriodByType('day');
        this.filterConfig.selectConfig.config.value = this.checkDetailService.statusPageTimeRange.id;
      }
    }
    this.checkID = +this.activatedRoute.snapshot.paramMap.get('id');
    this.targetSignature = this.activatedRoute.snapshot.paramMap.get('target');
    const activeTab = this.activatedRoute.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.currentTab = _.filter(this.tabs, {key: activeTab})[0];
    }
    const checkType = this.settingsService.getItem('checkType', this.checkID);
    if (checkType) {
      this.checkName = checkType.messageTitle;
    } else {
      this.checkName = 'Site-Wide';
    }

    this.events.subscribe('ccs:checkResponseUpdate', this.checkObservationListener);
  }

  ionViewDidEnter() {
    this.buildTargetDropDown();
    this.checkObservationListener();
  }

  public onPeriodChange(periodOption: any): void {
    if (periodOption.value) {
      this.applyTimeFilterPeriodByType(periodOption.value);
      this.checkObservationListener();
    }
  }

  public onTabChange(val) {
    setTimeout(() => {
      this.initDataTable(val.key);
    }, 100);
  }

  public back() {
    this.filterConfig.selectConfig.config.value = null; // clear out drop down
    if (this.backNavigation) {
      this.location.back()
    } else {
      this.router.navigate([`pages/dashboard/check-type-table/${this.checkID}`]);
    }
  }

  public onTargetChange(event) {
    this.targetSignature = event.value;
    this.checkObservationListener();
  }

  private checkObservationListener = async () => {
    if (!this.isLoading) {
      this.isLoading = true;

      await this.loadingService.enable();
      this.responses = values(await this.checkResponseService.getCheckResponseByCheckIDStatus(this.checkID, this.currentFilter, this.currentTeam, this.targetSignature)) || [];
      await this.getLoggedFlaggedIssues()
        .then(async () => {
          await this.countOfEachTable();
          this.loadingService.disable();
          this.initDataTable(this.currentTab.key);
        })
        .catch(() => this.loadingService.disable());

      this.isLoading = false;
    }
  };

  private async getLoggedFlaggedIssues() {
    const tableData = await this.checkDetailService.prepareIssueCaTable('logged', this.responses);
    const flaggedIssueIds: number[] = _.map(tableData, 'flaggedIssue');

    return flaggedIssueIds.length ? this.checkResponseService.getFlagIssue(flaggedIssueIds).then((flagIssues) => {
      this.flagIssues = flagIssues;
    }) : Promise.resolve();
  }

  private applyTimeFilterPeriodByType(type): void {
    this.checkDetailService.statusPageTimeRange.id = type;
    const times = this.utils.timespan(type);
    if (type !== 'yesterday') {
      this.currentFilter.startTime = times.startTime;
      this.currentFilter.endTime = null;
    } else if (type === 'yesterday') {
      this.currentFilter.startTime = times.startTime;
      this.currentFilter.endTime = times.endTime;
    } else {
      this.currentFilter = _.omit(this.currentFilter, ['startTime', 'endTime']);
    }
  }

  private async countOfEachTable() {
    _.each(['logged', 'corrective'], async data => {
      const tab: any = _.find(this.tabs, {key: data});
      const items = await this.checkDetailService.prepareIssueCaTable(data, this.responses);
      tab.postfix = '(' + items.length + ')';
    });
    _.each(['missed', 'skipped', 'complete'], async data => {
      const tab: any = _.find(this.tabs, {key: data});
      const item = await this.checkDetailService.prepareUnableMissed(data, this.currentFilter, this.checkID, this.currentTeam, this.targetSignature, this.responses);
      tab.postfix = '(' + item.length + ')';
    });
  }

  private async initDataTable(id) {
    const idReference: string = '#' + this.tableIds[id];
    let tableData: any = [];
    // get data by table type
    if (_.includes(['logged', 'corrective'], id)) {
      // get data for logged and corrective action
      tableData = await this.checkDetailService.prepareIssueCaTable(id, this.responses);

      if (id === 'logged') {
        const flaggedIssueIds: number[] = _.map(tableData, 'flaggedIssue');

        if (flaggedIssueIds.length) {
          _.each(tableData, (tableDataItem) => {
            const flaggedIssue = this.flagIssues[tableDataItem.flaggedIssue];

            if (flaggedIssue) {
              tableDataItem.notes = _.map(this.checkDetailService.getNotes(flaggedIssue), (note) => {
                note.value = note.data;
                return note;
              });
            }
          });
        }
      }
    }

    if (_.includes(['skipped', 'missed', 'complete'], id)) {
      // get data for logged and corrective action
      tableData = await this.checkDetailService.prepareUnableMissed(id, this.currentFilter, this.checkID, this.currentTeam, this.targetSignature, this.responses);
    }

    const preparedData = _.map(tableData, (item) => [
      ..._.map(this.checkDetailService.tableMap[id], (tableMapItem) => {
        if (_.isFunction(tableMapItem.data)) {
          return tableMapItem.data(item);
        }
        return _.get(item, tableMapItem.data);
      }),
      item
    ]);

    const columns = _.map(this.checkDetailService.tableMap[id], (column) => _.identity({
      title: column.title,
      render: column.render
    }));

    if ($(`${idReference} thead`).length) {
      const table: any = $(idReference).DataTable();
      table.clear().rows.add(preparedData).draw(false).columns.adjust();

      setTimeout(() => {
        table.columns.adjust().draw(true);
      });
      return;
    }

    const pagePadding = 100;
    const tableOffset: number = $(idReference).offset().top;
    if (tableOffset) {
      this.tableOffset = tableOffset;
    }
    const scrollY: number = $(window).height() - this.tableOffset - pagePadding;

    const table: any = $(idReference).DataTable(<any>{
      data: preparedData,
      stateSave: true,
      destroy: true,  // kills table and draws new when new data set comes in
      paging: true,  // imp for virtual scroll
      searching: false,  // top search bar for DT
      info: false,
      deferRender: true, // part of the plugin for virtual scroll, paging= true
      scroller: { // part of virtual scroll
        rowHeight: 65,
        displayBuffer: 18,
        loadingIndicator: true,
        boundaryScale: 0.15
      },
      scrollY: `${scrollY}px`,
      dom: 'Blfrtip', // initailized button elements
      buttons: {
        buttons: [
          $.extend(true, {}, {   // settings on how exported XLS should look like
            exportOptions: {
              columns: ':visible',
              orthogonal: 'export', // check on column builder to see how export type is defined
              stripNewlines: 'false',
            }
          }, {
            extend: 'excel',
            text: this.translate.instant('SHARED.Save_to_Excel'),
            className: 'button-styled'
          }),
          // {
          //   extend: 'colvis',  // settings for select/hide columns
          //   columns: ':not(.no-vis)',
          //   text: this.translate.instant('MGMT_LIST.Select_Columns'),
          //   className: 'button-styled',
          //   collectionLayout: 'three-column',
          // }
        ]
      },
      columnDefs: [{
        visible: false,
        targets: [columns.length]
      }],
      language: {
        emptyTable: this.translate.instant('SHARED.No_Activity'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords')
      },
      scrollX: true,
      order: [[0, 'desc']],
      columns
    });

    $(`${idReference} tbody`).off('click').on('click', 'tr', (event: Event) => {
      const data = _.last(table.row(event.currentTarget).data());
      this.checkDetailService.currentTableData = _.map(table.rows().data(), (rowItems) => _.last(rowItems));
      this.navByTableType(data);
    });

    $(idReference).width('100%');
    $(table.table().header()).addClass('table-header sorting');

    setTimeout(() => {
      table.columns.adjust().draw(true);
      this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, null);
      this.resizeHandler.addResizeHandler(id, () => this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, null));
    });
  }

  private async navByTableType(data) {
    // let's save the filter
    this.checkDetailService.statusPageTimeRange.currentFilter = this.currentFilter;
    switch (this.currentTab.key) {
      case 'logged': {
        // this would need detailed logged issue page
        this.router.navigate([`pages/dashboard/issue-detail/${data.responseID}/${data.flaggedIssue}`], {queryParams: { backNavigation: this.backNavigation }});
        break;
      }
      case 'corrective': {
        const observation = await this.observationService.getObservation(data.observationID);
        const queryParam = {
          superTaskBoard: !this.backNavigation,
          headerIsHidden: true,
          checkType: data.checkType,
          targetSignature: data.targetSignature
        };
        // let's clean the oids for CA detail back and forth
        const dataSet = _.map(this.checkDetailService.currentTableData, 'observationID');
        this.observationDetailService.navigateToDetail(observation, dataSet, !this.backNavigation, true, queryParam);
        //  this.router.navigate([`pages/dashboard/ca-detail/${observation.uuid}`])
        break;
      }
      case 'unable':
      case 'skipped':
      case 'complete': {
        this.router.navigate([`pages/dashboard/check-detail/${data.responseID}`], {queryParams: {skipFooter: this.backNavigation}});
        break;
      }
    }
  }

  private buildTargetDropDown() {
    const selectMenuObj: any = {
      data: this.checkDetailService.buildTargetMenu(),
      value: this.targetSignature,
      options: {
        minimumResultsForSearch: Infinity,
        sorter: (data) => data
      }
    };
    this.targetConfig = selectMenuObj;
  }

}
